import React from 'react';
import '../stylesheets/Hey.scss';

class Hey extends React.Component {
    render() {
        return (
            <svg viewBox="0 0 400 400" style={{transform: "translate(10px, 10px)"}}>
              <g>
    <g>
      <g id="hey_3">
        <g id="y_1">
          <g id="y_fill">
            <polygon points="376.48 210.01 352.82 196.36 329.45 210.01 352.97 223.58 376.48 210.01" fill="#de6c84"/>
            <polygon points="305.93 169.26 305.79 223.62 352.97 250.68 352.97 304.95 376.48 318.52 376.48 209.97 352.97 196.4 353.11 223.62 329.45 209.97 329.45 182.83 305.93 169.26" fill="#c9d7ee"/>
            <polygon points="305.79 169.1 329.59 155.61 353.25 169.1 329.45 182.83 305.79 169.1" fill="#de6c84"/>
            <polygon points="400 196.28 376.34 182.63 352.97 196.28 376.48 209.85 400 196.28" fill="#de6c84"/>
            <polygon points="329.45 209.97 352.97 196.28 353.25 169.1 329.45 182.83 329.45 209.97" fill="#facdd6"/>
            <polygon points="376.48 318.52 400 304.95 400 196.28 376.48 209.85 376.48 318.52" fill="#facdd6"/>
          </g>
        </g>
        <g id="e_1">
          <g id="e_fill">
            <polygon points="235.38 209.89 258.9 196.32 305.79 223.46 282.42 236.94 235.38 209.89" fill="#de6c84"/>
            <polygon points="235.38 169.18 258.9 155.61 305.79 182.75 282.42 196.24 235.38 169.18" fill="#de6c84"/>
            <polygon points="235.38 155.69 235.38 237.11 282.42 264.25 282.42 237.11 258.76 223.62 258.9 209.97 282.42 223.54 282.42 196.4 258.9 182.83 258.9 169.26 282.42 182.83 282.42 155.69 235.38 128.55 235.38 155.69" fill="#c9d7ee"/>
            <polygon points="282.42 237.11 305.79 223.62 305.79 250.59 282.42 264.25 282.42 237.11" fill="#facdd6"/>
            <polygon points="282.42 196.32 305.79 182.83 305.79 209.81 282.42 223.46 282.42 196.32" fill="#facdd6"/>
            <polygon points="282.42 155.61 305.79 142.12 305.79 169.1 282.42 182.75 282.42 155.61" fill="#facdd6"/>
            <polygon points="235.38 128.55 258.9 114.98 305.79 142.12 282.42 155.61 235.38 128.55" fill="#de6c84"/>
          </g>
        </g>
        <g id="h_1">
          <g id="h_fill">
            <polygon points="164.9 87.76 188.42 74.19 188.42 182.67 164.76 196.4 164.9 87.76" fill="#facdd6"/>
            <polygon points="141.31 20 141.31 182.83 164.83 196.4 164.83 114.98 188.35 128.55 188.35 209.97 211.72 223.62 211.87 114.98 164.83 87.85 164.83 34.06 141.31 20" fill="#c9d7ee"/>
            <polygon points="211.87 114.98 235.38 101.42 235.38 209.89 211.72 223.62 211.87 114.98" fill="#facdd6"/>
            <polygon points="164.83 34.06 188.35 20 188.42 74.24 164.83 87.85 164.83 34.06" fill="#facdd6"/>
            <polygon points="164.83 87.85 188.42 74.24 235.38 101.42 211.87 114.98 164.83 87.85" fill="#de6c84"/>
            <polygon points="141.31 20 164.76 6.39 188.35 20 164.83 34.06 141.31 20" fill="#de6c84"/>
          </g>
        </g>
      </g>
      <g id="hey_2">
        <g id="y_1-2" data-name="y_1">
          <g id="y_fill-2" data-name="y_fill">
            <polygon points="329.38 237.17 305.72 223.52 282.35 237.17 305.86 250.74 329.38 237.17" fill="#de6c84"/>
            <polygon points="258.83 196.42 258.69 250.78 305.86 277.84 305.86 332.11 329.38 345.68 329.38 237.13 305.86 223.56 306 250.78 282.35 237.13 282.35 209.99 258.83 196.42" fill="#c9d7ee"/>
            <polygon points="258.69 196.26 282.49 182.77 306.15 196.26 282.35 209.99 258.69 196.26" fill="#de6c84"/>
            <polygon points="352.89 223.44 329.24 209.78 305.86 223.44 329.38 237 352.89 223.44" fill="#de6c84"/>
            <polygon points="282.35 237.13 305.86 223.44 306.15 196.26 282.35 209.99 282.35 237.13" fill="#facdd6"/>
            <polygon points="329.38 345.68 352.89 332.11 352.89 223.44 329.38 237 329.38 345.68" fill="#facdd6"/>
          </g>
        </g>
        <g id="e_1-2" data-name="e_1">
          <g id="e_fill-2" data-name="e_fill">
            <polygon points="188.28 237.05 211.79 223.48 258.69 250.62 235.31 264.1 188.28 237.05" fill="#de6c84"/>
            <polygon points="188.28 196.34 211.79 182.77 258.69 209.91 235.31 223.39 188.28 196.34" fill="#de6c84"/>
            <polygon points="188.28 182.85 188.28 264.27 235.31 291.4 235.31 264.27 211.65 250.78 211.79 237.13 235.31 250.7 235.31 223.56 211.79 209.99 211.79 196.42 235.31 209.99 235.31 182.85 188.28 155.71 188.28 182.85" fill="#c9d7ee"/>
            <polygon points="235.31 264.27 258.69 250.78 258.69 277.75 235.31 291.4 235.31 264.27" fill="#facdd6"/>
            <polygon points="235.31 223.48 258.69 209.99 258.69 236.96 235.31 250.62 235.31 223.48" fill="#facdd6"/>
            <polygon points="235.31 182.77 258.69 169.28 258.69 196.26 235.31 209.91 235.31 182.77" fill="#facdd6"/>
            <polygon points="188.28 155.71 211.79 142.14 258.69 169.28 235.31 182.77 188.28 155.71" fill="#de6c84"/>
          </g>
        </g>
        <g id="h_1-2" data-name="h_1">
          <g id="h_fill-2" data-name="h_fill">
            <polygon points="117.8 114.92 141.31 101.35 141.31 209.82 117.66 223.56 117.8 114.92" fill="#facdd6"/>
            <polygon points="94.21 47.16 94.21 209.99 117.73 223.56 117.73 142.14 141.24 155.71 141.24 237.13 164.62 250.78 164.76 142.14 117.73 115.01 117.73 61.22 94.21 47.16" fill="#c9d7ee"/>
            <polygon points="164.76 142.14 188.28 128.57 188.28 237.05 164.62 250.78 164.76 142.14" fill="#facdd6"/>
            <polygon points="117.73 61.22 141.24 47.16 141.31 101.4 117.73 115.01 117.73 61.22" fill="#facdd6"/>
            <polygon points="117.73 115.01 141.31 101.4 188.28 128.57 164.76 142.14 117.73 115.01" fill="#de6c84"/>
            <polygon points="94.21 47.16 117.66 33.55 141.24 47.16 117.73 61.22 94.21 47.16" fill="#de6c84"/>
          </g>
        </g>
      </g>
      <g id="hey_1">
        <g id="y_1-3" data-name="y_1">
          <g id="y_fill-3" data-name="y_fill">
            <polygon points="282.27 264.33 258.61 250.68 235.24 264.33 258.76 277.9 282.27 264.33" fill="#de6c84"/>
            <polygon points="211.72 223.58 211.58 277.94 258.76 305 258.76 359.27 282.27 372.84 282.27 264.29 258.76 250.72 258.9 277.94 235.24 264.29 235.24 237.15 211.72 223.58" fill="#c9d7ee"/>
            <polygon points="211.58 223.41 235.38 209.93 259.04 223.41 235.24 237.15 211.58 223.41" fill="#de6c84"/>
            <polygon points="305.79 250.59 282.13 236.94 258.76 250.59 282.27 264.16 305.79 250.59" fill="#de6c84"/>
            <polygon points="235.24 264.29 258.76 250.59 259.04 223.41 235.24 237.15 235.24 264.29" fill="#facdd6"/>
            <polygon points="282.27 372.84 305.79 359.27 305.79 250.59 282.27 264.16 282.27 372.84" fill="#facdd6"/>
          </g>
        </g>
        <g id="e_1-3" data-name="e_1">
          <g id="e_fill-3" data-name="e_fill">
            <polygon points="141.17 264.2 164.69 250.64 211.58 277.77 188.21 291.26 141.17 264.2" fill="#de6c84"/>
            <polygon points="141.17 223.5 164.69 209.93 211.58 237.07 188.21 250.55 141.17 223.5" fill="#de6c84"/>
            <polygon points="141.17 210.01 141.17 291.43 188.21 318.56 188.21 291.43 164.55 277.94 164.69 264.29 188.21 277.86 188.21 250.72 164.69 237.15 164.69 223.58 188.21 237.15 188.21 210.01 141.17 182.87 141.17 210.01" fill="#c9d7ee"/>
            <polygon points="188.21 291.43 211.58 277.94 211.58 304.91 188.21 318.56 188.21 291.43" fill="#facdd6"/>
            <polygon points="188.21 250.64 211.58 237.15 211.58 264.12 188.21 277.77 188.21 250.64" fill="#facdd6"/>
            <polygon points="188.21 209.93 211.58 196.44 211.58 223.41 188.21 237.07 188.21 209.93" fill="#facdd6"/>
            <polygon points="141.17 182.87 164.69 169.3 211.58 196.44 188.21 209.93 141.17 182.87" fill="#de6c84"/>
          </g>
        </g>
        <g id="h_1-3" data-name="h_1">
          <g id="h_fill-3" data-name="h_fill">
            <polygon points="70.69 142.08 94.21 128.51 94.21 236.98 70.55 250.72 70.69 142.08" fill="#facdd6"/>
            <polygon points="47.1 74.32 47.1 237.15 70.62 250.72 70.62 169.3 94.14 182.87 94.14 264.29 117.51 277.94 117.66 169.3 70.62 142.16 70.62 88.38 47.1 74.32" fill="#c9d7ee"/>
            <polygon points="117.66 169.3 141.17 155.73 141.17 264.2 117.51 277.94 117.66 169.3" fill="#facdd6"/>
            <polygon points="70.62 88.38 94.14 74.32 94.21 128.56 70.62 142.16 70.62 88.38" fill="#facdd6"/>
            <polygon points="70.62 142.16 94.21 128.56 141.17 155.73 117.66 169.3 70.62 142.16" fill="#de6c84"/>
            <polygon points="47.1 74.32 70.55 60.71 94.14 74.32 70.62 88.38 47.1 74.32" fill="#de6c84"/>
          </g>
        </g>
      </g>
      <g id="hey" data-name="hey">
        <g id="y">
          <g id="y_fill-4" data-name="y_fill">
            <polygon points="235.17 291.49 211.51 277.84 188.13 291.49 211.65 305.06 235.17 291.49" fill="#de6c84"/>
            <polygon points="164.62 250.74 164.47 305.1 211.65 332.15 211.65 386.43 235.17 400 235.17 291.45 211.65 277.88 211.79 305.1 188.13 291.45 188.13 264.31 164.62 250.74" fill="#c9d7ee"/>
            <polygon points="164.47 250.57 188.28 237.09 211.94 250.57 188.13 264.31 164.47 250.57" fill="#de6c84"/>
            <polygon points="258.69 277.75 235.03 264.1 211.65 277.75 235.17 291.32 258.69 277.75" fill="#de6c84"/>
            <polygon points="188.13 291.45 211.65 277.75 211.94 250.57 188.13 264.31 188.13 291.45" fill="#facdd6"/>
            <polygon points="235.17 400 258.69 386.43 258.69 277.75 235.17 291.32 235.17 400" fill="#facdd6"/>
          </g>
          <g id="y_stroke">
            <polyline points="235.17 291.49 211.51 277.84 188.13 291.49 211.65 305.06" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <polygon points="164.62 250.74 164.47 305.1 211.65 332.15 211.65 386.43 235.17 400 235.17 291.45 211.65 277.88 211.79 305.1 188.13 291.45 188.13 264.31 164.62 250.74" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <polygon points="164.47 250.57 188.28 237.09 211.94 250.57 188.13 264.31 164.47 250.57" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <polygon points="258.69 277.75 235.03 264.1 211.65 277.75 235.17 291.32 258.69 277.75" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <polygon points="188.13 291.45 211.65 277.75 211.94 250.57 188.13 264.31 188.13 291.45" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <polygon points="235.17 400 258.69 386.43 258.69 277.75 235.17 291.32 235.17 400" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
          </g>
        </g>
        <g id="e">
          <g id="e_fill-4" data-name="e_fill">
            <polygon points="94.07 291.36 117.58 277.8 164.47 304.93 141.1 318.42 94.07 291.36" fill="#de6c84"/>
            <polygon points="94.07 250.66 117.58 237.09 164.47 264.23 141.1 277.71 94.07 250.66" fill="#de6c84"/>
            <polygon points="94.07 237.17 94.07 318.58 141.1 345.72 141.1 318.58 117.44 305.1 117.58 291.45 141.1 305.02 141.1 277.88 117.58 264.31 117.58 250.74 141.1 264.31 141.1 237.17 94.07 210.03 94.07 237.17" fill="#c9d7ee"/>
            <polygon points="141.1 318.58 164.47 305.1 164.47 332.07 141.1 345.72 141.1 318.58" fill="#facdd6"/>
            <polygon points="141.1 277.8 164.47 264.31 164.47 291.28 141.1 304.93 141.1 277.8" fill="#facdd6"/>
            <polygon points="141.1 237.09 164.47 223.6 164.47 250.57 141.1 264.23 141.1 237.09" fill="#facdd6"/>
            <polygon points="94.07 210.03 117.58 196.46 164.47 223.6 141.1 237.09 94.07 210.03" fill="#de6c84"/>
          </g>
          <g id="e_stroke">
            <polyline points="152.84 298.2 164.47 304.93 141.1 318.42" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <polyline points="152.82 257.48 164.47 264.23 141.1 277.71" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <polygon points="94.07 237.17 94.07 318.58 141.1 345.72 141.1 318.58 117.44 305.1 117.58 291.45 141.1 305.02 141.1 277.88 117.58 264.31 117.58 250.74 141.1 264.31 141.1 237.17 94.07 210.03 94.07 237.17" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <polygon points="141.1 318.58 164.47 305.1 164.47 332.07 141.1 345.72 141.1 318.58" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <polyline points="141.1 277.8 164.47 264.31 164.47 291.28 141.1 304.93 141.1 277.8" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <polygon points="141.1 237.09 164.47 223.6 164.47 250.57 141.1 264.23 141.1 237.09" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <polygon points="94.07 210.03 117.58 196.46 164.47 223.6 141.1 237.09 94.07 210.03" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
          </g>
        </g>
        <g id="h">
          <g id="h_fill-4" data-name="h_fill">
            <polygon points="23.59 169.24 47.1 155.67 47.1 264.14 23.45 277.88 23.59 169.24" fill="#facdd6"/>
            <polygon points="0 101.48 0 264.31 23.52 277.88 23.52 196.46 47.03 210.03 47.03 291.45 70.41 305.1 70.55 196.46 23.52 169.32 23.52 115.54 0 101.48" fill="#c9d7ee"/>
            <polygon points="70.55 196.46 94.07 182.89 94.07 291.36 70.41 305.1 70.55 196.46" fill="#facdd6"/>
            <polygon points="23.52 115.54 47.03 101.48 47.1 155.72 23.52 169.32 23.52 115.54" fill="#facdd6"/>
            <polygon points="23.52 169.32 47.1 155.72 94.07 182.89 70.55 196.46 23.52 169.32" fill="#de6c84"/>
            <polygon points="0 101.48 23.45 87.87 47.03 101.48 23.52 115.54 0 101.48" fill="#de6c84"/>
          </g>
          <g id="h_stroke">
            <polygon points="23.55 196.46 47.03 210.03 47.1 264.14 23.45 277.88 23.55 196.46" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <polygon points="0 101.48 0 264.31 23.52 277.88 23.52 196.46 47.03 210.03 47.03 291.45 70.41 305.1 70.55 196.46 23.52 169.32 23.52 115.54 0 101.48" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <polygon points="70.55 196.46 94.07 182.89 94.07 291.36 70.41 305.1 70.55 196.46" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <polygon points="23.52 115.54 47.03 101.48 47.1 155.72 23.52 169.32 23.52 115.54" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <polygon points="23.52 169.32 47.1 155.72 94.07 182.89 70.55 196.46 23.52 169.32" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <polygon points="0 101.48 23.45 87.87 47.03 101.48 23.52 115.54 0 101.48" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
        );
    }
}

export default Hey;