import React from 'react';
import PropTypes from 'prop-types';
import Donut from './Donut';
import Image from './Image'

import './stylesheets/Section.scss';

class Section extends React.Component {
    constructor(props) {
        super(props);

        this.isFirstAndHasAnimated = false; // Used so that first page animates on load -- super hacky sorry :\
    }

    isDisplayed() {
        if (this.props.num === 0 && !this.isFirstAndHasAnimated) {
            this.isFirstAndHasAnimated = true;
            setTimeout(() => {
                this.forceUpdate();
            }, 50)
            return false;
        }
        return this.props.percentScroll > 0.15 && this.props.percentScroll < 0.85;
    }

    getDonutProps() {
        return {
            percentFull: this.props.percentScroll,
            color: this.props.donutColor,
        }
    }

    getImageProps() {
        return {
            mounted: this.isDisplayed(),
            image: this.props.floatingImage,
        }
    }

    render() {
        return (
            <div className="section_contentContainer">
                <div>
                    <div className="section_donutImageContainer">
                        <Donut {... this.getDonutProps()} />
                        <Image {... this.getImageProps()} />
                    </div>
                </div>
                <div className={`section_textContainer_${this.isDisplayed() ? "mounted" : "unmounted"}`}>
                    <h2>{this.props.title}</h2>
                    <div>{this.props.description}</div>
                </div>
            </div>
        );
    }
}

Section.propTypes = {
    num: PropTypes.number,
    percentScroll: PropTypes.number,
    floatingImage: PropTypes.element,
    donutColor: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.element,
}

export default Section;