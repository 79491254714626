import React from 'react';
import '../stylesheets/Laptop.scss';

class Laptop extends React.Component {
    render() {
        return (
            <svg viewBox="0 0 400 400">
                <g id="SCREEN_FRAME" data-name="SCREEN FRAME">
        <g id="screen_frame-2" data-name="screen frame">
        <polygon points="133.92 161.48 142.38 156.29 362.41 283.25 353.73 288.57 133.92 161.48" fill="#b5969d"/>
        <polygon points="143.59 0.44 143.59 157.1 135.14 161.74 135.14 5.63 143.59 0.44" fill="#67809e"/>
        <path d="M133.92,5.64v156.1L353.73,288.57V132.72ZM347.75,281.58,137.43,160.33V17.66L347.75,139.18Z" fill="#de6c84"/>
        <polygon points="362.41 283.5 362.3 283.75 353.73 288.57 353.73 132.72 362.41 127.4 362.41 283.5" fill="#7ba6d8"/>
        <polygon points="133.92 5.63 142.38 0.44 362.41 127.4 353.73 132.72 133.92 5.63" fill="#facdd6"/>
        </g>
    </g>
    <g id="MUG">
        <g id="mug-2" data-name="mug">
        <polygon points="17.56 162.28 40.68 148.93 40.68 122.25 17.56 135.59 17.56 162.28" fill="#67809e"/>
        <polygon points="63.8 162.28 63.8 135.59 40.68 122.25 40 148.54 63.8 162.28" fill="#a7596a"/>
        <path d="M40.68,122.25,17.56,135.59l23.12,13.34L63.8,135.59ZM24.33,135.59l16.35-9.43L57,135.59,40.68,145Z" fill="#facdd6"/>
        <polygon points="40.68 185.84 40.68 148.93 17.56 135.59 16.88 172.1 40.68 185.84" fill="#de6c84"/>
        <polygon points="40.68 185.45 63.8 172.1 63.8 135.59 40.68 148.93 40.68 185.45" fill="#7ba6d8"/>
        <g id="handle">
            <polygon points="55.7 146.37 50.02 149.65 61.38 156.2 67.01 152.9 55.7 146.37" fill="#facdd6"/>
            <polygon points="55.7 159.47 50.02 162.74 61.38 169.3 67.01 166 55.7 159.47" fill="#facdd6"/>
            <polygon points="61.38 175.86 67.05 172.59 67.01 152.9 61.38 156.2 61.38 175.86" fill="#7ba6d8"/>
            <polygon points="50.02 156.2 50.02 149.65 61.38 156.2 61.38 175.86 50.02 169.31 50.02 162.76 55.7 166.03 55.7 159.48 50.02 156.2" fill="#de6c84"/>
        </g>
        </g>
    </g>
    <g id="KEYBOARD">
        <g id="keyboard-2" data-name="keyboard">
        <g id="keyboard_base" data-name="keyboard base">
            <polygon points="90.65 177.19 6.11 226.57 225.92 353.11 310.46 303.7 90.65 177.19" fill="#facdd6"/>
            <polygon points="6.11 235.79 6.11 226.57 225.92 353.11 225.92 362.62 6.11 235.79" fill="#de6c84"/>
            <polygon points="310.46 313.82 310.46 303.7 225.92 353.11 225.92 362.62 310.46 313.82" fill="#7ba6d8"/>
        </g>
        <g id="space_bar" data-name="space bar">
            <polygon points="62.51 230.69 49.54 238.18 192.19 320.48 205.16 313 62.51 230.69" fill="#facdd6"/>
            <polygon points="49.54 244.76 49.54 238.18 192.19 320.48 205.16 313 205.16 319.58 192.11 327.01 49.54 244.76" fill="#de6c84"/>
        </g>
        </g>
    </g>
    <g id="TRACKPAD">
        <g id="trackpad-2" data-name="trackpad">
        <polygon points="325.67 313.53 258.51 352.75 326.74 392.01 393.89 352.75 325.67 313.53" fill="#facdd6"/>
        <polygon points="258.51 360.07 258.51 352.75 326.74 392.01 326.74 399.56 258.51 360.07" fill="#de6c84"/>
        <polygon points="393.89 360.79 393.89 352.75 326.74 392.01 326.74 399.56 393.89 360.79" fill="#7ba6d8"/>
        </g>
    </g>
    <g id="SCREEN">
        <g id="screen-2" data-name="screen">
        <polygon points="105.39 33.28 105.39 157.72 325.2 284.55 325.2 160.12 105.39 33.28" fill="#7ba6d8" fillOpacity="0.5" stroke="#231f20" strokeMiterlimit="10"/>
        <line x1="325.2" y1="160.11" x2="105.39" y2="33.28" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
        <polygon points="230.53 105.15 230.53 229.81 325.2 284.66 325.2 160.01 230.53 105.15" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
        <polygon points="230.53 179.85 230.53 229.81 325.2 284.66 325.2 234.71 230.53 179.85" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
        <polygon points="105.39 21.14 105.39 33.28 325.2 160.12 325.2 147.97 105.39 21.14" fill="#fff" fillOpacity="0.5" stroke="#231f20" strokeMiterlimit="10"/>
        <g>
            <path d="M117.82,102.05l0-11.58,6,3.48v2.32l2,1.16v2.32l-2-1.16v2.32l2,1.16v2.31l-2-1.16v2.32Zm6,1.17v-2.31l-4-2.32v2.31Zm-4-7,4,2.32V96.27l-4-2.32Z" fill="#231f20"/>
            <path d="M127.91,107.87l0-11.6,6,3.48v2.32l-4-2.32v2.32l4,2.32v2.32l-4-2.32v2.32l4,2.32v2.32Z" fill="#231f20"/>
            <path d="M135.94,112.51l0-11.6,6,3.48v2.32l-4-2.32v2.32l4,2.32v2.32L138,109v2.32l4,2.32V116Z" fill="#231f20"/>
            <path d="M144,117.16l0-11.6,6,3.48v2.32l2,1.16v2.32l-2-1.16V116l-4-2.32,0,4.64Zm6.06-3.47v-2.32L146,109v2.32Z" fill="#231f20"/>
            <path d="M160.09,126.44l0-11.59,6,3.48v2.32l2,1.16v2.32l-2-1.16v2.32l2,1.16v2.31l-2-1.16v2.32Zm6,1.16V125.3l-4-2.32v2.31Zm-4-6.95,4,2.32v-2.32l-4-2.32Z" fill="#231f20"/>
            <path d="M172.19,131.1l-2-1.16,0-7,2,1.16v-2.32l4,2.32v2.32l2,1.16,0,7-2-1.16v2.32l-4-2.32Zm0,0,4,2.32,0-7-4-2.32Z" fill="#231f20"/>
            <path d="M182.26,136.91l-2-1.16,0-7,2,1.16v-2.32l4,2.32v2.32l2,1.16,0,7-2-1.16v2.32l-4-2.32Zm0,0,4,2.32,0-7-4-2.32Z" fill="#231f20"/>
            <path d="M190.29,143.87l0-11.6,6,3.48v2.32l2,1.16v2.32l-2-1.16v2.32l-4-2.32,0,4.64Zm6.06-3.47v-2.32l-4-2.32v2.32Z" fill="#231f20"/>
            <path d="M202.39,148.52v2.32l-2-1.16v-2.32Zm0-9.28,0,7-2-1.16,0-7Z" fill="#231f20"/>
        </g>
        </g>
    </g>
            </svg>
        );
    }
}

export default Laptop;