import React from 'react';
import PropTypes from 'prop-types';

import './stylesheets/HoverImage.scss';

const HoverImage = (props) => {
    const inner = (
        <div className="hoverImage_container">
            <div className="hoverImage_text">{props.children}</div>
            <div className="hoverImage_imageContainer">
                <img src={props.imgSrc} alt={props.children}/>
            </div>
        </div>
    )

    if (props.noExternal) {
        return inner;
    } else {
        return (
            <a href={props.imgSrc} target="_blank" rel="noopener noreferrer">
                {inner}
            </a>
        )
    }
}

HoverImage.propTypes = {
    imgSrc: PropTypes.string,
    noExternal: PropTypes.bool,
}

export default HoverImage;