import React from 'react';
import PropTypes from 'prop-types';
import Nav from './Nav';
import Section from './Section';

import './stylesheets/Main.css';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            percentScroll: 0.5,
            currSectionIndex: 0,
        }
        this.fullPageHeight = window.innerHeight;
        this.scrollRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('mousewheel', this.bodyMouseWheel)
    }

    componentWillUnmount() {
        window.removeEventListener('mousewheel')
    }

    bodyMouseWheel = (e) => {
        e.preventDefault()
        this.scrollRef.current.scrollTop += (e.deltaY / 2)
        this.handleScroll()
    }

    handleScroll = () => {
        let scrollTop = this.scrollRef.current.scrollTop;
        let percentScroll = scrollTop / this.fullPageHeight - this.state.currSectionIndex + 0.5;

        if (percentScroll > 1 || percentScroll < 0) {
            this.switchSections(percentScroll);
        } else {
            this.setState({ percentScroll: percentScroll });
        }
    }

    switchSections = (percentScroll) => {
        let newIndex = Math.max(0, this.state.currSectionIndex + Math.floor(percentScroll / 1));
        let newPercentScroll = (percentScroll > 0) ? percentScroll % 1 : 1 + (percentScroll % 1);

        this.scrollRef.current.scrollTop = this.fullPageHeight * (newIndex + newPercentScroll - 0.5);

        this.setState({
            currSectionIndex: newIndex,
            percentScroll: newPercentScroll,
        });
    }

    switchToSectionNum = (n) => {
        this.setState({
            currSectionIndex: n,
            percentScroll: 0.5,
        });
        this.scrollRef.current.scrollTop = this.fullPageHeight * (n);
    }

    render() {
        return (
            <>
                <Nav
                    sections={this.props.content.slice(1).map(c => { return {title: c.title, color: c.donutColor}} )}
                    currIndex={this.state.currSectionIndex}
                    changeSections={this.switchToSectionNum} 
                />
                <div ref={this.scrollRef} onScroll={this.handleScroll} onClick={this.handleClick} className="section_scrollContainer">
                    <div style={{height: this.props.content.length * this.fullPageHeight + "px"}} className="section_scrollManager"></div>
                </div>
                <Section
                    num={this.state.currSectionIndex}
                    percentScroll={this.state.percentScroll}
                    {... this.props.content[this.state.currSectionIndex]}
                />
            </>
        );
    }
}

Main.propTypes = {
    content: PropTypes.array,
}

export default Main;