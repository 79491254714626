import React from 'react';
import PropTypes from 'prop-types';

import './stylesheets/Donut.css';

class Donut extends React.PureComponent {
    render() {
        return (
            <div className="donut_container">
                <svg viewBox="0 0 500 500" className="donut">
				    <circle
                        cx="250"
                        cy="250"
                        r="200"
                        fill="transparent"
                        strokeWidth="50px"
                        stroke={this.props.color}
                        strokeDashoffset={this.props.percentFull * -2 * 1300 + 1300}
                        strokeDasharray={1300}
                    />
			    </svg>
            </div>
        );
    }
}

Donut.propTypes = {
    percentFull: PropTypes.number,
    color: PropTypes.string,
}

export default Donut;