import React from 'react';
import PropTypes from 'prop-types';

import './stylesheets/Nav.scss';

class Nav extends React.Component {
    render() {
        let navLinks = this.props.sections.map((sectionInfo, i) => {
            const {title, color} = sectionInfo;
            const selected = (i + 1) === this.props.currIndex;
            return (
                <div
                    key={title}
                    onClick={() => this.props.changeSections(i + 1)}
                    className={selected ? "selected" : ""}
                    style={selected ? { color: color } : {}}
                >
                    {title}
                </div>
            );
        });
        return (
            <header>
                <h3 onClick={() => this.props.changeSections(0)} id="nav_home">Emily Zhong</h3>
                <div className="nav_number_container">
                    <div className="nav_number">
                        {`${this.props.currIndex + 1} / ${this.props.sections.length + 1}`}
                    </div>
                    <nav>{navLinks}</nav>
                </div>
            </header>
        )
    }
}

Nav.protoTypes = {
    sections: PropTypes.array,
    currIndex: PropTypes.number,
    changeSections: PropTypes.func,
}

export default Nav;