import React from 'react';
import '../stylesheets/Blocks.scss';

class Blocks extends React.Component {
    render() {
        return (
            <svg viewBox="0 0 400 400">
                <g id="BLOCKS">
    <g>
      <g id="BASE">
          <g>
        <g id="BASE_FILL">
          <polygon points="189.47 313.92 210.53 326.07 400 215.61 315.79 170.02 231.58 216.75 231.58 265.33 210.53 253.19 189.47 265.33 189.47 313.92" fill="#7ba6d8"/>
          <polygon points="294.74 206.46 294.74 157.87 210.53 206.46 210.53 255.05 294.74 206.46" fill="#facdd6"/>
          <polygon points="168.42 157.87 231.58 121.43 147.37 72.84 63.16 121.43 84.03 133.57 104.91 121.43 168.42 157.87" fill="#7ba6d8"/>
          <polygon points="210.53 327.93 210.53 230.75 168.42 206.46 168.42 157.87 126.32 133.57 126.32 84.98 83.97 60.55 84.03 133.57 63.16 121.43 62.97 170.02 42.1 157.87 42.1 230.75 210.53 327.93" fill="#c9d7ee"/>
          <polygon points="168.42 60.69 168.42 109.28 126.32 133.57 126.32 84.98 168.42 60.69" fill="#facdd6"/>
          <polygon points="83.97 60.55 126.32 36.4 168.42 60.69 126.32 84.98 83.97 60.55" fill="#7ba6d8"/>
          <polygon points="231.58 121.43 231.58 145.72 252.63 133.57 252.63 157.87 168.42 206.46 168.42 157.87 231.58 121.43" fill="#facdd6"/>
          <polygon points="252.63 133.57 231.58 121.43 231.58 145.72 252.63 133.57" fill="#7ba6d8"/>
          <polygon points="231.58 218.6 231.58 218.6 210.53 206.46 294.74 157.87 315.79 170.02 357.89 145.72 273.44 96.99 252.63 109.28 210.53 84.98 189.47 97.13 252.63 133.57 252.63 157.87 168.42 206.46 210.53 230.75 231.58 218.6" fill="#7ba6d8"/>
          <polygon points="210.53 327.93 231.58 315.78 231.58 218.6 210.53 230.75 210.53 327.93" fill="#facdd6"/>
          <polygon points="315.79 170.02 315.79 218.6 294.74 206.46 294.74 157.87 315.79 170.02" fill="#c9d7ee"/>
          <polygon points="63.16 121.43 83.97 109.42 84.03 133.57 63.16 121.43" fill="#7ba6d8"/>
          <polygon points="42.1 157.63 62.92 145.62 62.98 169.78 42.1 157.63" fill="#7ba6d8"/>
          <polygon points="357.89 145.72 357.89 170.02 336.84 182.16 336.84 206.46 315.79 218.6 315.79 170.02 357.89 145.72" fill="#facdd6"/>
          <polygon points="400 215.61 400 264.2 189.47 386.81 189.47 338.22 400 215.61" fill="#facdd6"/>
          <polygon points="189.47 338.22 189.47 387.9 0 277.48 0 228.89 189.47 338.22" fill="#c9d7ee"/>
          <polygon points="0 228.89 42.1 204.6 42.1 230.75 210.53 326.07 189.47 338.22 0 228.89" fill="#7ba6d8"/>
        </g>
        <g id="BASE_STROKE">
          <polygon points="294.74 206.46 294.74 157.87 210.53 206.46 231.58 218.6 231.58 242.9 294.74 206.46" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
          <polyline points="210.53 230.75 168.42 206.46 168.42 157.87 126.32 133.57 126.32 84.98 83.97 60.55 84.03 133.57 63.16 121.43 62.97 170.02 42.1 157.87 42.1 230.75" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
          <polygon points="168.42 60.69 168.42 109.28 126.32 133.57 126.32 84.98 168.42 60.69" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
          <polygon points="83.97 60.55 126.32 36.4 168.42 60.69 126.32 84.98 83.97 60.55" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
          <polygon points="231.58 121.43 231.58 145.72 252.63 133.57 252.63 157.87 168.42 206.46 168.42 157.87 231.58 121.43" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
          <polygon points="252.63 133.57 231.58 121.43 231.58 145.72 252.63 133.57" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
          <polygon points="231.58 218.6 231.58 218.6 210.53 206.46 294.74 157.87 315.79 170.02 357.89 145.72 273.44 96.99 252.63 109.28 210.53 84.98 189.47 97.13 252.63 133.57 252.63 157.87 168.42 206.46 210.53 230.75 231.58 218.6" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
          <polyline points="231.58 313.92 231.58 218.6 210.53 230.75 210.53 326.07" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
          <polygon points="315.79 170.02 315.79 218.6 294.74 206.46 294.74 157.87 315.79 170.02" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
          <polygon points="63.16 121.43 83.97 109.42 84.03 133.57 63.16 121.43" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
          <polygon points="42.1 157.63 62.92 145.62 62.98 169.78 42.1 157.63" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
          <polygon points="357.89 145.72 357.89 170.02 336.84 182.16 336.84 206.46 315.79 218.6 315.79 170.02 357.89 145.72" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
          <polygon points="400 215.61 400 264.2 189.47 386.81 189.47 338.22 400 215.61" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
          <polygon points="189.47 338.22 189.47 387.9 0 277.48 0 228.89 189.47 338.22" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
          <polygon points="0 228.89 42.1 204.6 42.1 230.75 210.53 326.07 189.47 338.22 0 228.89" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
          <line x1="189.47" y1="97.13" x2="168.42" y2="84.11" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
          <line x1="400" y1="215.61" x2="336.84" y2="182.16" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
        </g>
        </g>
      </g>
      <g id="BLOCK1">
          <g>
        <g id="BLOCK1_FILL"><polygon points="124.6 279.22 104.37 290.9 105.35 193.15 126.4 181 124.6 279.22" fill="#facdd6"/>
                    <polygon points="63.07 267.55 104.37 290.9 105.35 193.15 84.06 180.87 84.06 229.46 63.56 217.63 63.07 267.55" fill="#c9d7ee"/>
          <polygon points="105.35 168.86 84.06 180.87 105.35 193.15 126.4 181 105.35 168.86" fill="#7ba6d8"/>
          <polygon points="84.06 205.16 63.56 217.63 84.06 229.46 84.06 205.16" fill="#7ba6d8"/>
        </g>
        <g id="BLOCK1_STROKE">
          <g>
            <polyline points="124.65 276.23 124.6 279.22 122 280.72" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <line x1="116.99" y1="283.61" x2="109.47" y2="287.95" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="5.79 5.79"/>
            <polyline points="106.97 289.4 104.37 290.9 104.4 287.9" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <line x1="104.46" y1="281.78" x2="105.29" y2="199.21" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="6.12 6.12"/>
            <polyline points="105.32 196.15 105.35 193.15 107.95 191.65" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <line x1="113.23" y1="188.6" x2="121.16" y2="184.03" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="6.1 6.1"/>
            <polyline points="123.81 182.5 126.4 181 126.35 184" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <line x1="126.24" y1="190.15" x2="124.71" y2="273.15" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="6.15 6.15"/>
          </g>
          <g>
            <polyline points="63.1 264.55 63.07 267.55 65.68 269.02" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <line x1="70.83" y1="271.94" x2="99.18" y2="287.96" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="5.92 5.92"/>
            <polyline points="101.76 289.42 104.37 290.9 104.4 287.9" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <line x1="104.46" y1="281.78" x2="105.29" y2="199.21" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="6.12 6.12"/>
            <polyline points="105.32 196.15 105.35 193.15 102.75 191.65" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <line x1="97.39" y1="188.56" x2="89.34" y2="183.91" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="6.19 6.19"/>
            <polyline points="86.66 182.37 84.06 180.87 84.06 183.87" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <line x1="84.06" y1="189.95" x2="84.06" y2="223.41" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="6.08 6.08"/>
            <polyline points="84.06 226.46 84.06 229.46 81.47 227.96" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <line x1="76.36" y1="225.01" x2="68.71" y2="220.6" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="5.89 5.89"/>
            <polyline points="66.16 219.13 63.56 217.63 63.53 220.63" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <line x1="63.47" y1="226.9" x2="63.13" y2="261.41" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="6.27 6.27"/>
          </g>
          <g>
            <polyline points="107.95 170.35 105.35 168.86 102.74 170.33" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <line x1="97.38" y1="173.35" x2="89.35" y2="177.88" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="6.15 6.15"/>
            <polyline points="86.68 179.39 84.06 180.87 86.66 182.37" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <line x1="92.03" y1="185.46" x2="100.07" y2="190.1" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="6.19 6.19"/>
            <polyline points="102.75 191.65 105.35 193.15 107.95 191.65" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <line x1="113.23" y1="188.6" x2="121.16" y2="184.03" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="6.1 6.1"/>
            <polyline points="123.81 182.5 126.4 181 123.81 179.5" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <line x1="118.52" y1="176.45" x2="110.59" y2="171.88" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="6.1 6.1"/>
          </g>
          <g>
            <polyline points="84.06 208.16 84.06 205.16 81.5 206.72" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <line x1="76.37" y1="209.84" x2="68.69" y2="214.51" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="6 6"/>
            <polyline points="66.12 216.07 63.56 217.63 66.16 219.13" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <line x1="71.26" y1="222.07" x2="78.91" y2="226.49" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="5.89 5.89"/>
            <polyline points="81.47 227.96 84.06 229.46 84.06 226.46" fill="none" stroke="#231f20" strokeMiterlimit="10"/>
            <line x1="84.06" y1="220.36" x2="84.06" y2="211.21" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="6.1 6.1"/>
          </g>
        </g>
        </g>
      </g>
      <g id="BLOCK2">
          <g>
        <g id="BLOCK2_FILL">
          <polygon points="105.35 24.25 105.35 48.54 126.4 60.69 126.4 36.4 105.35 24.25" fill="#c9d7ee"/>
          <polygon points="147.46 48.54 147.46 24.25 126.4 36.4 126.4 60.69 147.46 48.54" fill="#facdd6"/>
          <polygon points="126.4 12.1 105.35 24.25 126.4 36.4 147.46 24.25 126.4 12.1" fill="#7ba6d8"/>
        </g>
        <g id="BLOCK2_STROKE">
          <polygon points="105.35 24.25 105.35 48.54 126.4 60.69 126.4 36.4 105.35 24.25" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="6"/>
          <polygon points="147.46 48.54 147.46 24.25 126.4 36.4 126.4 60.69 147.46 48.54" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="6"/>
          <polygon points="126.4 12.1 105.35 24.25 126.4 36.4 147.46 24.25 126.4 12.1" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="6"/>
        </g>
      </g>
      </g>
      <g id="BLOCK3"><g>
        <g id="BLOCK3_FILL">
          <polygon points="252.63 204.85 252.63 229.15 273.68 241.29 273.68 217 252.63 204.85" fill="#c9d7ee"/>
          <polygon points="315.79 217 315.79 192.7 273.68 217 273.68 241.29 315.79 217" fill="#facdd6"/>
          <polygon points="294.74 180.56 252.63 204.85 273.68 217 315.79 192.7 294.74 180.56" fill="#7ba6d8"/>
        </g>
        <g id="BLOCK3_STROKE">
          <polygon points="252.63 204.85 252.63 229.15 273.68 241.29 273.68 217 252.63 204.85" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="6"/>
          <polygon points="315.79 217 315.79 192.7 273.68 217 273.68 241.29 315.79 217" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="6"/>
          <polyline points="315.79 192.7 294.74 180.56 252.63 204.85" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="6"/>
        </g>
      </g></g>
      <g id="BLOCK4"><g>
        <g id="BLOCK4_FILL">
          <polygon points="147.37 96.25 147.37 120.55 168.42 132.7 168.42 108.4 147.37 96.25" fill="#c9d7ee"/>
          <polygon points="189.47 120.55 189.47 96.25 168.42 108.4 168.42 132.7 189.47 120.55" fill="#facdd6"/>
          <polygon points="168.42 84.11 147.37 96.25 168.42 108.4 189.47 96.25 168.42 84.11" fill="#7ba6d8"/>
        </g>
        <g id="BLOCK4_STROKE">
          <polygon points="147.37 96.25 147.37 120.55 168.42 132.7 168.42 108.4 147.37 96.25" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="6"/>
          <polygon points="189.47 120.55 189.47 96.25 168.42 108.4 168.42 132.7 189.47 120.55" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="6"/>
          <polygon points="168.42 84.11 147.37 96.25 168.42 108.4 189.47 96.25 168.42 84.11" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeDasharray="6"/>
        </g>
      </g>
      </g>
    </g>
  </g>
            </svg>
        );
    }
}

export default Blocks;