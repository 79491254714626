import React from 'react';
import Main from './Main';
import Hey from './images/Hey';
import Laptop from './images/Laptop';
import Conveyer from './images/Conveyer';
import Blocks from './images/Blocks';
import Contact from './images/Contact';
import HoverImage from './HoverImage';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import './stylesheets/App.scss';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailCopied: false,
    }
  }

  render() {
    const content = [
      {
        title: "I'm Emily",
        description: (
          <p>I'm a recent computer science graduate from UC Berkeley and part-time master's student at Stanford.
          I'm care about intentional thinking, design, and engineering.</p>
        ),
        floatingImage: <Hey />,
        donutColor: "#DE6C83",
      }, {
        title: "Work",
        description: (
          <div>
            <p>I'm currently building <a href="https://www.figma.com/c" target="_blank" rel="noopener noreferrer">community</a> for <a href="https://www.figma.com/" target="_blank" rel="noopener noreferrer" >Figma</a> as a software engineer.</p>
            <p>
              I've also previously interned at
            </p>
            <ul>
              <li><a href="https://www.figma.com" target="_blank" rel="noopener noreferrer">Figma</a>, 2020</li>
              <li><a href="https://chanzuckerberg.com/" target="_blank" rel="noopener noreferrer" >Chan Zuckerberg Initiative</a>, 2019</li>
              <li><a href="https://www.google.com/" target="_blank" rel="noopener noreferrer" >Google</a>, 2017 &amp; 2018</li>
            </ul>
          </div>
        ),
        floatingImage: <Laptop />,
        donutColor: "#DE6C83",
      }, {
        title: "Projects",
        description: (
          <div>
            <p>Things I've coded</p>
            <ul>
              <li>A <a href="https://www.figma.com/community/plugin/831000487189051522/Figma-Tetris" target="_blank" rel="noopener noreferrer">plugin</a> to play Tetris in a Figma file</li>
              <li>A <a href="https://chrome.google.com/webstore/detail/piazza-but-prettier/mfglplbmjmaicgmohiaedebficgdhgkh/" target="_blank" rel="noopener noreferrer" >chrome extension</a> that
              makes <a href="https://piazza.com" target="_blank" rel="noopener noreferrer" >Piazza</a>, a educational classroom q&amp;a platform, cleaner and easier to use</li>
              <li>A <a href="http://projects.dailycal.org/2017/where-we-cry/" target="_blank" rel="noopener noreferrer" >map</a> of where and why people have cried at Berkeley, The Daily Californian's 2017 Mental Health special issue</li>
            </ul>
            <p>Things I've designed</p>
            <ul>
              <li>Currently typesetting Bojack Horseman's "Free Churro" episode
                script <a href="https://emilyzhong.me/free_churro/" target="_blank" rel="noopener noreferrer" >here</a></li>
              <li><HoverImage imgSrc="sather_gate.png">Various</HoverImage> <HoverImage imgSrc="poker.png">pieces</HoverImage> <HoverImage imgSrc="fakenews.jpg">of</HoverImage> <HoverImage imgSrc="planets.png">graphic</HoverImage> <HoverImage imgSrc="marchmadness.png">design</HoverImage>,
              including the illustrations + animations on this website!</li>
              <li><a href="https://instagram.com/sketchyemily" target="_blank" rel="noopener noreferrer" >Digital illustrations and doodles</a></li>
            </ul>
          </div>
        ),
        floatingImage: <Conveyer />,
        donutColor: "#7ba6d8",
      }, {
        title: "Initiatives",
        description: (
          <div>
            <p>I've been lucky to be able to build with some great groups of people. Some in the past include</p>
            <ul>
              <li>Website design and various illustrations for <a href="https://stanfordrewired.com" target="_blank" rel="noopener noreferrer" >Rewired</a>,
              a digital magazine about tech and society.</li>
              <li>Integration of designers into <a href="https://calblueprint.org">Blueprint</a>'s
            project teams, to create better technology-based tools for non-profits</li>
              <li>Cubstart, a beginner hackathon program with <a href="https://calhacks.io" target="_blank" rel="noopener noreferrer" >Cal Hacks</a></li>
              <li>Curriculum development for <a href="https://wdd.io" target="_blank" rel="noopener noreferrer" >Web Design DeCal</a></li>
            </ul>
          </div>
        ),
        floatingImage: <Blocks />,
        donutColor: "#DE6C83",
      }, {
        title: "Let's talk!",
        description: (
          <div>
            <p>I love meeting and working with people.
              <br></br>
              Shoot me an email at <CopyToClipboard
                text="emily.zhong@berkeley.edu"
                onCopy={() => {this.setState({ emailCopied: true })}}>
                <span className={`email-copy${this.state.emailCopied ? " copied" : ""}`}>emily.zhong@berkeley.edu</span>
              </CopyToClipboard>.
            </p>
            <div id="icon-links">
              <a href="./emilyzhong_resume.pdf" target="_blank" el="noopener noreferrer">Résumé</a>
              <a href="https://github.com/emilyzhong" target="_blank" rel="noopener noreferrer">Github</a>
              <a href="https://linkedin.com/in/emily-zhong" target="_blank" rel="noopener noreferrer">Linkedin</a>
              <a href="https://figma.com/@emily" target="_blank" rel="noopener noreferrer">Figma</a>
              <HoverImage imgSrc="me.gif">:)</HoverImage>
            </div>
          </div>
        ),
        floatingImage: <Contact />,
        donutColor: "#7ba6d8",
      },
    ]
    return (
      <div className="App">
        <Main content={content} />
      </div>
    );
  }
}

export default App;
