import React from 'react';
import '../stylesheets/Conveyer.scss';

class Conveyer extends React.Component {
    render() {
        return (
            <svg viewBox="0 -20 400 400">
                <defs>
    <clipPath id="clipPath">
      <polygon points="0.86 249.74 111.49 313.86 375.63 158.9 266.96 95.26 0.86 249.74" fill="none"/>
    </clipPath>
    <clipPath id="clipPath-2">
      <rect x="46.82" y="213.67" width="73.61" height="28.67" fill="none"/>
    </clipPath>
    <clipPath id="clipPath-3">
      <rect x="52.31" y="242.34" width="69.47" height="22.25" fill="none"/>
    </clipPath>
  </defs>
  <title>belt</title>
  <g id="CONVEYER">
    <g>
      <g id="MACHINE_BACK">
        <polygon id="MACHINE_BACK_PANEL" points="72.56 115.37 154.58 67.99 154.43 209.06 72.41 256.44 72.56 115.37" fill="#b5969d"/>
        <polyline id="MACHINE_BACK1" points="93.07 174.06 92.93 173.88 92.93 268.37 72.71 256.53 72.5 114.71 154.49 161.93" fill="#de6c84"/>
      </g>
      <g id="BELT">
        <g id="BELT_BACK">
          <path d="M35.15,240.93C16.09,251.93.5,275.28.5,292.83s15.57,22.88,34.61,11.85l229.8-133.2c19-11,34.61-34.24,34.61-51.57s-15.59-22.52-34.65-11.52Z" fill="#de6c84"/>
        </g>
        <g id="BELT_TOP">
          <path d="M41.52,245.86c-19.06,11-34.65,34.35-34.65,51.9s15.57,22.88,34.61,11.85l229.8-133.2c19-11,34.61-34.24,34.61-51.57s-15.59-22.52-34.65-11.52Z" fill="#7ba6d8"/>
          <polygon points="30.85 253.98 111.49 313.86 358.54 159.44 276.62 112.18 30.85 253.98" fill="#7ba6d8"/>
          <polygon points="12.95 312.13 99.75 360.01 115.06 301.34 31.52 253.46 12.95 312.13" fill="#7ba6d8"/>
          <polygon points="240.39 146.91 322.31 194.18 381.5 157.63 299.58 110.36 240.39 146.91" fill="#7ba6d8"/>
        </g>
        <g id="TRACKS_CLIPPED">
          <g clipPath="url(#clipPath)">
          <g id="TRACKS">
              <line x1="24.29" y1="258.22" x2="106.17" y2="305.46" fill="none" stroke="#67809e" strokeMiterlimit="10"/>
              <line x1="44.76" y1="246.41" x2="126.64" y2="293.65" fill="none" stroke="#67809e" strokeMiterlimit="10"/>
              <line x1="65.23" y1="234.6" x2="147.11" y2="281.84" fill="none" stroke="#67809e" strokeMiterlimit="10"/>
              <line x1="85.98" y1="222.79" x2="167.86" y2="270.03" fill="none" stroke="#67809e" strokeMiterlimit="10"/>
              <line x1="106.17" y1="210.98" x2="188.04" y2="258.22" fill="none" stroke="#67809e" strokeMiterlimit="10"/>
              <line x1="126.92" y1="199.16" x2="208.8" y2="246.41" fill="none" stroke="#67809e" strokeMiterlimit="10"/>
              <line x1="147.15" y1="187.35" x2="229.03" y2="234.6" fill="none" stroke="#67809e" strokeMiterlimit="10"/>
              <line x1="167.62" y1="175.54" x2="249.5" y2="222.79" fill="none" stroke="#67809e" strokeMiterlimit="10"/>
              <line x1="-70.91" y1="315.61" x2="10.97" y2="362.86" fill="none" stroke="#67809e" strokeMiterlimit="10"/>
              <line x1="-50.73" y1="303.8" x2="31.15" y2="351.05" fill="none" stroke="#67809e" strokeMiterlimit="10"/>
              <line x1="-29.97" y1="291.99" x2="51.91" y2="339.23" fill="none" stroke="#67809e" strokeMiterlimit="10"/>
              <line x1="-9.74" y1="280.18" x2="72.13" y2="327.42" fill="none" stroke="#67809e" strokeMiterlimit="10"/>
              <line x1="10.73" y1="268.37" x2="92.6" y2="315.61" fill="none" stroke="#67809e" strokeMiterlimit="10"/>
              <line x1="239.24" y1="122.39" x2="321.12" y2="169.64" fill="none" stroke="#67809e" strokeMiterlimit="10"/>
              <line x1="259.71" y1="110.58" x2="341.59" y2="157.83" fill="none" stroke="#67809e" strokeMiterlimit="10"/>
              <line x1="280.18" y1="98.77" x2="362.06" y2="146.02" fill="none" stroke="#67809e" strokeMiterlimit="10"/>
              <line x1="188.09" y1="163.73" x2="269.97" y2="210.98" fill="none" stroke="#67809e" strokeMiterlimit="10"/>
              <line x1="208.84" y1="151.92" x2="290.72" y2="199.16" fill="none" stroke="#67809e" strokeMiterlimit="10"/>
              <line x1="229.03" y1="140.11" x2="310.91" y2="187.35" fill="none" stroke="#67809e" strokeMiterlimit="10"/>
              <line x1="249.78" y1="128.3" x2="331.66" y2="175.54" fill="none" stroke="#67809e" strokeMiterlimit="10"/>
              <line x1="269.97" y1="116.49" x2="351.84" y2="163.73" fill="none" stroke="#67809e" strokeMiterlimit="10"/>
              <line x1="290.72" y1="104.68" x2="372.6" y2="151.92" fill="none" stroke="#67809e" strokeMiterlimit="10"/>
            </g>
          </g>
        </g>
        <g id="BELT_FRONT">
          <path d="M126.34,293.12c-19.06,11-34.65,34.35-34.65,51.9s15.57,22.88,34.61,11.85l229.8-133.2c19-11,34.61-34.24,34.61-51.57s-15.59-22.52-34.65-11.52Z" fill="#de6c84"/>
          <path d="M135.13,297.66c-19.06,11-34.65,34.35-34.65,51.9s15.57,22.88,34.61,11.85l229.8-133.2c19-11,34.61-34.24,34.61-51.57s-15.59-22.52-34.65-11.52Z" fill="#facdd6"/>
        </g>
      </g>
      <g id="PLANTER">
        <g id="BACK">
          <polygon points="63.34 268.03 86.83 255.79 86.83 228.89 63.52 242.34 63.34 268.03" fill="#b5969d" stroke="#231f20" strokeMiterlimit="10"/>
          <polygon points="110.14 267.74 110.14 242.34 86.83 228.89 86.15 255.39 110.14 267.74" fill="#a7596a" stroke="#231f20" strokeMiterlimit="10"/>
          <g id="BACK-2" data-name="BACK">
            <g clipPath="url(#clipPath-2)">
              <path d="M86.83,228.89,63.52,242.34l23.31,13.45,23.31-13.45Zm16.48,13.45-16.48,9.51-16.48-9.51m0,0,16.48-9.51,16.48,9.51" fill="#7ba6d8" stroke="#231f20" strokeMiterlimit="10"/>
            </g>
          </g>
        </g>
        <g id="PLANT">
          <polygon points="70.21 250.65 87.04 241.56 104.95 251.9 87.58 261.61 70.21 250.65" fill="#67809e" stroke="#231f20" strokeMiterlimit="10"/>
          <g>
            <path d="M72.4,231.3c-5.29,8.14,13.5,16.06,13.5,16.06C87.69,240.73,72.4,231.3,72.4,231.3Z" fill="#c9d7ee" stroke="#231f20" strokeMiterlimit="10"/>
            <path d="M105.12,227.62C83.26,229,86.88,247.7,86.88,247.7,102.08,244.76,105.12,227.62,105.12,227.62Z" fill="#c9d7ee" stroke="#231f20" strokeMiterlimit="10"/>
            <path d="M83.6,216c-10.6,9.68.72,29.48.72,29.48C90.68,236.44,83.6,216,83.6,216Z" fill="#c9d7ee" stroke="#231f20" strokeMiterlimit="10"/>
          </g>
        </g>
        <g id="PLANT_FRONT">
          <polyline points="63.52 242.34 63.34 268.03 86.83 281.58 86.83 255.79 63.52 242.34" fill="#de6c84" stroke="#231f20" strokeMiterlimit="10"/>
          <polyline points="110.14 242.34 86.83 255.79 86.83 281.19 110.14 267.74 110.14 242.34" fill="#facdd6" stroke="#231f20" strokeMiterlimit="10"/>
          <g id="top">
            <g clipPath="url(#clipPath-3)">
              <path d="M86.83,228.89,63.52,242.34l23.31,13.45,23.31-13.45Zm16.48,13.45-16.48,9.51-16.48-9.51m0,0,16.48-9.51,16.48,9.51" fill="#7ba6d8" stroke="#231f20" strokeMiterlimit="10"/>
            </g>
          </g>
        </g>
      </g>
    </g>
      <g id="NOTES">
        <g id="PAPER">
          <polygon points="285.13 125.54 250.51 163.65 311.95 175.05 324.39 129.82 285.13 125.54" fill="#a7596a" stroke="#231f20" strokeMiterlimit="10"/>
          <polygon points="252.56 156.03 305.4 125.54 345.04 148.4 292.19 178.89 252.56 156.03" fill="#de6c84" stroke="#231f20" strokeMiterlimit="10"/>
        </g>
        <g id="PAPER_WORK">
          <polygon points="306.4 130.29 302.92 132.3 330.82 148.4 334.31 146.39 306.4 130.29" fill="#c9d7ee"/>
          <polygon points="299.74 133.16 296.25 135.17 324.16 151.27 327.65 149.26 299.74 133.16" fill="#c9d7ee"/>
          <polygon points="293.19 136.81 289.7 138.82 317.61 154.93 321.1 152.91 293.19 136.81" fill="#c9d7ee"/>
          <polygon points="286.59 140.78 283.1 142.79 311.01 158.9 314.49 156.88 286.59 140.78" fill="#c9d7ee"/>
          <polygon points="279.92 143.65 276.44 145.66 304.34 161.76 307.83 159.75 279.92 143.65" fill="#c9d7ee"/>
          <polygon points="273.38 147.31 269.89 149.32 297.8 165.42 301.28 163.41 273.38 147.31" fill="#c9d7ee"/>
        </g>
      </g>
      <g id="MACHINE_FRONT">
        <polygon id="MACHINE_FRONT1" points="93.07 174.06 195.42 233.11 194.8 327.24 215.6 339.34 216.03 197.3 92.93 126.7 93.07 174.06" fill="#de6c84"/>
        <polygon id="MACHINE_FRONT_PANEL" points="215.89 197.5 297.92 150.12 297.63 291.96 215.6 339.34 215.89 197.5" fill="#facdd6"/>
        <polygon id="MACHINE_TOP" points="72.6 114.22 154.63 66.84 297.77 149.76 215.75 197.14 72.6 114.22" fill="#c9d7ee"/>
      </g>
  </g>
            </svg>
        );
    }
}

export default Conveyer;