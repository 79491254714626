import React from 'react';
import PropTypes from 'prop-types';
import './stylesheets/Image.css';

class Image extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {
                transform: "translateY(200px)",
                opacity: 0,
            },
        }
    }

    componentDidMount() {
        if (this.props.mounted) {
            this.mountStyle();
        }
    }

    componentWillReceiveProps(newProps) { 
        if (!newProps.mounted)
          return this.unMountStyle() 
        setTimeout(this.mountStyle, 10) 
    }

    unMountStyle = () => { 
        this.setState({
            style: {
                transform: "translateY(200px)",
                opacity: 0,
            }
        });
    }

    mountStyle = () => { // css for mount animation
        this.setState({
            style: {
                transform: "translateY(0px)",
                opacity: 1,
            }
        });
    }

    render() {
        return (
            <div className="image_container">
                <div className={`img_${this.props.mounted ? "mounted" : "unmounted"}`}>
                    {this.props.image}
                </div>
            </div>
        )
    }
}

Image.propTypes = {
    scrollPercent: PropTypes.number,
    image: PropTypes.object,
}

export default Image;