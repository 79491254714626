import React from 'react';
import '../stylesheets/Contact.scss';

class Contact extends React.Component {
    render() {
        return (
            <svg viewBox="0 0 400 400">
                <g id="CONTACT">
                    <g id="MSG3">
                        <g opacity="0.7">
                            <polygon opacity="0.7" fill="#7BA6D8" points="182.9,0 182.9,188.4 202.5,199.7 202.4,237 241.6,222.2 398,312.5 398,124.1 			
                                "/>
                            <polygon fill="#C9D7EE" points="192.7,39.5 192.7,62.1 368.7,163.6 368.7,141.1 			"/>
                            <polygon fill="#C9D7EE" points="192.7,69.7 192.7,81 368.7,182.6 368.7,171.3 			"/>
                            <polygon fill="#C9D7EE" points="192.7,87.7 192.7,99 368.7,200.6 368.7,189.3 			"/>
                            <polygon fill="#C9D7EE" points="192.7,112.7 192.7,124 368.7,225.6 368.7,214.3 			"/>
                            <polygon fill="#C9D7EE" points="192.7,135.4 192.7,146.7 241.6,174.8 241.6,163.5 			"/>
                        </g>
                    </g>
                    <g id="MSG2">
                        <g opacity="0.8">
                            <polygon fill="#FACDD6" points="129.1,36.2 129.1,217.6 256.7,290.6 296,351.8 296,313.5 344.2,341.7 344.2,160.4 			"/>
                            <polygon fill="#DE6C84" points="138.9,75.7 138.9,98.3 314.9,199.9 314.9,177.3 			"/>
                            <polygon fill="#DE6C84" points="138.9,106 138.9,117.3 314.9,218.8 314.9,207.5 			"/>
                            <polygon fill="#DE6C84" points="138.9,124 138.9,135.3 314.9,236.8 314.9,225.6 			"/>
                            <polygon fill="#DE6C84" points="138.9,149 138.9,160.2 314.9,261.8 314.9,250.5 			"/>
                            <polygon fill="#DE6C84" points="266,242.1 266,253.4 314.9,281.5 314.9,270.2 			"/>
                        </g>
                    </g>
                    <g id="MSG1">
                        <g opacity="0.9">
                            <polygon fill="#7BA6D8" points="62,69 62,257.3 81.6,268.6 81.6,305.9 120.7,291.2 277.1,381.5 277.1,193.1 			"/>
                            <polygon fill="#C9D7EE" points="71.8,108.5 71.8,131 247.8,232.6 247.8,210 			"/>
                            <polygon fill="#C9D7EE" points="71.8,138.7 71.8,150 247.8,251.5 247.8,240.2 			"/>
                            <polygon fill="#C9D7EE" points="71.8,156.7 71.8,168 247.8,269.5 247.8,258.3 			"/>
                            <polygon fill="#C9D7EE" points="71.8,181.7 71.8,193 247.8,294.5 247.8,283.2 			"/>
                            <polygon fill="#C9D7EE" points="71.8,204.4 71.8,215.7 120.7,243.7 120.7,232.5 			"/>
                        </g>
                    </g>
                    <g id="FRONT">
                        <g>
                            <polygon fill="white" fillOpacity="0.5" stroke="#000000" strokeMiterlimit="10" points="2,87.5 2,275.9 21.5,287.2 21.5,324.5 60.6,309.7 
                                217.1,400 217.1,211.6 		"/>
                            <g>
                                <path d="M40.2,210l0.1-16.3l2.8,1.6l0,6.5l5.7,3.3l0-6.5l2.8,1.6l-0.1,16.3l-2.8-1.6l0-6.5l-5.7-3.3l0,6.5L40.2,210z"/>
                                <path d="M54.3,218.1l0.1-16.3l8.5,4.9l0,3.3l-5.7-3.3l0,3.3l5.7,3.3l0,3.3l-5.7-3.3l0,3.3l5.7,3.3l0,3.3L54.3,218.1z"/>
                                <path d="M68.5,210l0,3.3l2.8,1.6l0,3.3l2.8,1.6l0-3.3l2.8,1.6l0-3.3l2.8,1.6l0,3.3l-2.8-1.6l0,3.3l-2.8-1.6l0,9.8l-2.8-1.6l0-9.8
                                    l-2.8-1.6l0-3.3l-2.8-1.6l0-3.3L68.5,210z"/>
                                <path d="M99.6,228l0,3.3l-2.8-1.6l0,13l-2.8-1.6l0-13l-2.8-1.6l0-3.3L99.6,228z"/>
                                <path d="M102.4,245.9l0.1-16.3l2.8,1.6l0,6.5l5.7,3.3l0-6.5l2.8,1.6l-0.1,16.3l-2.8-1.6l0-6.5l-5.7-3.3l0,6.5L102.4,245.9z"/>
                                <path d="M116.6,254.1l0.1-16.3l8.5,4.9l0,3.3l-5.7-3.3l0,3.3l5.7,3.3l0,3.3l-5.7-3.3l0,3.3l5.7,3.3l0,3.3L116.6,254.1z"/>
                                <path d="M127.9,260.6l0.1-16.3l8.5,4.9l0,3.3l2.8,1.6l0,3.3l-2.8-1.6l0,3.3l-5.7-3.3l0,6.5L127.9,260.6z M136.4,255.7l0-3.3
                                    l-5.7-3.3l0,3.3L136.4,255.7z M136.4,259l0,6.5l2.8,1.6l0-6.5L136.4,259z"/>
                                <path d="M142,268.8l0.1-16.3l8.5,4.9l0,3.3l-5.7-3.3l0,3.3l5.7,3.3l0,3.3l-5.7-3.3l0,3.3l5.7,3.3l0,3.3L142,268.8z"/>
                                <path d="M156.2,273.7l0,3.3l-2.8-1.6l0-3.3L156.2,273.7z M156.2,260.6l0,9.8l-2.8-1.6l0-9.8L156.2,260.6z"/>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}

export default Contact;